<div class="info-modal info-modal-container seaz-modal__apply">
  <div class="info-modal__header">
    <h2 class="seaz-h2 seaz-modal__apply_title">
      <ng-container *ngIf="data?.title">
        {{ data.translate ? (data.title | transloco) : data.title }}
      </ng-container>
    </h2>
  </div>
  <div class="seaz-modal__apply_text_box" [class.no-title]="!data?.title">
    <ng-container *ngIf="data?.message">
      <div [innerHTML]="data.translate ? (data.message | transloco) : data.message" class="seaz-modal__apply_text">
      </div>
      <app-seazone-icon
        *ngIf="data?.icon; else smile"
        [name]="data.icon"
        [ngClass]="data.icon"
        class="seaz-modal__apply_icon"></app-seazone-icon>
      <ng-template #smile>
        <ng-container *ngIf="!data?.hideSmile">
          <span class="seaz-modal__apply_icon">😉</span>
        </ng-container>
      </ng-template>
    </ng-container>
  </div>
  <div class="info-modal__controls seaz-modal__apply_btn_box">
    <button class="seaz__btn_primary" (click)="onClose()">{{ 'actions.ok' | transloco }}</button>
  </div>
</div>
