<div class="info-modal info-modal-container">
  <div class="info-modal__header">
    <h2 *ngIf="!!data" class="title-h1 text-center">{{ data?.title | transloco }}</h2>
    <div class="info-modal__header-icons">
      <img src="assets/img/logo_img_square.svg" alt="Logo" class="info-modal__header_logo" />
      <img src="assets/img/logo_text_dark.svg" alt="Logo title" />
    </div>
  </div>
  <div class="info-modal__content">
    {{ data?.message | transloco }}
  </div>
  <div class="info-modal__controls">
    <button class="main-btn" (click)="onClose()">{{ 'actions.ok' | transloco }}</button>
  </div>
</div>
