<div class="info-modal">
  <h3 class="seaz-title-h3">
    <ng-container *ngIf="data?.title">
      {{ data.translate ? (data.title | transloco) : data.title }}
    </ng-container>
  </h3>

  <div class="service-brands-modal">
    <ng-container *ngIf="data?.message">
      <p class="info-modal__text" [innerHTML]="data.translate ? (data.message | transloco) : data.message"> </p>
    </ng-container>

    <div class="info-modal__controls">
      <button class="seaz__btn_rounded" (click)="onClose()">{{ 'actions.ok' | transloco }}</button>
    </div>
  </div>
</div>
