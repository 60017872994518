import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';

import {InfoModalComponent} from './components/info-modal/info-modal.component';
import {RoundedInfoModalComponent} from './components/rounded-info-modal/rounded-info-modal.component';
import {SeazInfoModalComponent} from './components/seaz-info-modal/seaz-info-modal.component';
import {InfoModalDirective} from './directives/info-modal.directive';
import {InfoModalService} from './services/info-modal.service';

import {ModalModule} from '../modal';
import {SeazoneIconsModule} from '../seazone-icons';

@NgModule({
  declarations: [InfoModalComponent, InfoModalDirective, SeazInfoModalComponent, RoundedInfoModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    TranslocoModule,
    SeazoneIconsModule,
  ],
  providers: [InfoModalService],
  exports: [InfoModalDirective],
})
export class InfoModalModule {
}
