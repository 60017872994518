import {Injectable} from '@angular/core';
import {OverlayCloseEvent} from '@modules/modal/interfaces/overlay-close-event';
import {SeazoneIcon} from '@modules/seazone-icons';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {OverlayService} from '../../modal';
import {InfoModalComponent} from '../components/info-modal/info-modal.component';
import {RoundedInfoModalComponent} from '../components/rounded-info-modal/rounded-info-modal.component';
import {SeazInfoModalComponent} from '../components/seaz-info-modal/seaz-info-modal.component';

@Injectable()
export class InfoModalService {

  constructor(
    private readonly overlayService: OverlayService,
  ) {
  }

  open(message: string, title = ''): Observable<OverlayCloseEvent<null>> {
    const ref = this.overlayService.open(InfoModalComponent, {message, title}, {disposeOnNavigation: true});
    return ref ? ref.afterClosed$ : EMPTY;
  }

  openSeazThemeModal(
    message: string,
    title = '',
    translate = false,
    icon: SeazoneIcon | null = null,
    hideSmile = false,
    additionalClass: string | null = null,
  )
    : Observable<boolean | null> {
    const ref = this.overlayService.open(SeazInfoModalComponent,
      {message, title, translate, icon, hideSmile},
      {
        panelClass: ['seaz-modal', 'modal-small', additionalClass || ''],
        backdropClass: ['seaz-background', 'modal-background'],
        hasBackdrop: true,
        disposeOnNavigation: true,
      },
    );
    return ref ? ref.afterClosed$.pipe(map(event => event.data)) : EMPTY;
  }

  openRoundedModal(
    message: string,
    title = '',
    translate = false,
    disposeOnNavigation = true,
  ): Observable<boolean | null> {
    const ref = this.overlayService.open(RoundedInfoModalComponent,
      {message, title, translate},
      {
        panelClass: ['seaz-modal', 'modal-small', 'seaz-rounded'],
        backdropClass: ['seaz-background', 'modal-background'],
        hasBackdrop: true,
        disposeOnNavigation,
      },
    );
    return ref ? ref.afterClosed$.pipe(map(event => event.data)) : EMPTY;
  }
}
