import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {CustomOverlayRef, MODAL_DATA} from '@modules/modal';

import {InfoModalDataI} from '../../models/info-modal';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoModalComponent implements OnInit {

  constructor(
    private readonly dialogRef: CustomOverlayRef,
    @Inject(MODAL_DATA) public readonly data: InfoModalDataI,
  ) { }

  ngOnInit() {
  }

  onClose(): void {
    this.dialogRef.close(null);
  }

}
