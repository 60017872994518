import {Component, Inject} from '@angular/core';

import {CustomOverlayRef, MODAL_DATA} from '../../../modal';
import {SeazRoundedModalDataI} from '../../models/info-modal';

@Component({
  selector: 'app-rounded-info-modal',
  templateUrl: './rounded-info-modal.component.html',
  styleUrls: ['./rounded-info-modal.component.scss'],
})
export class RoundedInfoModalComponent {

  constructor(
    private readonly dialogRef: CustomOverlayRef,
    @Inject(MODAL_DATA) public readonly data: SeazRoundedModalDataI,
  ) { }

  onClose(): void {
    this.dialogRef.close(true);
  }

}
