import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {CustomOverlayRef, MODAL_DATA} from '@modules/modal/classes';

import {SeazInfoModalDataI} from '../../models/info-modal';

@Component({
  selector: 'app-seaz-info-modal',
  templateUrl: './seaz-info-modal.component.html',
  styleUrls: ['./seaz-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeazInfoModalComponent implements OnInit {

  constructor(
    private readonly dialogRef: CustomOverlayRef,
    @Inject(MODAL_DATA) public readonly data: SeazInfoModalDataI,
  ) { }

  ngOnInit() {
  }

  onClose(): void {
    this.dialogRef.close(true);
  }

}
